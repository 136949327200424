import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, map,} from 'rxjs';
import { Auth, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Firestore, getFirestore, doc, docData, collectionSnapshots, collection, getDoc, updateDoc, setDoc, DocumentReference, addDoc, where, query, getDocs, limit} from '@angular/fire/firestore';
import { User } from '../_interfaces/user';
import { Absentstamp, TimestampList } from '../_interfaces/time-stamp-object';
import { AlertMessageService } from './alert-message.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  //-------------------- Variable - Constructor --------------------//
  private auth: Auth;
  private firestore: Firestore;

  private timestampDocRef: DocumentReference;

  private alertMessageSevice: AlertMessageService|undefined = inject(AlertMessageService);;
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private timestampObservable: BehaviorSubject<TimestampList> = new BehaviorSubject<TimestampList>(null);
  private appUserObservable: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private appUserSubscriper: Subscription|null = null;

  private currentTimestampList: TimestampList;
  private appUser: User;


  constructor(private authFirebaseModul: AngularFireAuth, private firestoreModul: AngularFirestore) {
    this.auth = getAuth();
    this.firestore = getFirestore();

    this.authStatusListener(true);
  }

  //-------------------- Public Function --------------------//
  //-------------------- Get Function --------------------//

  getAuthentication(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  observeTimestamp(): Observable<TimestampList> {
    return this.timestampObservable.asObservable();
  }

  getAppUser(): Observable<User> {
    return this.appUserObservable.asObservable();
  }


  subscribeUserWithUid(_userDocPath: string): Observable<User> {
    const userDocRef = doc(this.firestore, `User/${_userDocPath}`);
    return (docData(userDocRef) as Observable<User>);
    //return this.firestoreModul.collection<User>('User').doc(_uid).valueChanges();
  }

  subscribeTimestampFromUser(_timestampDocPath: string): Observable<TimestampList>{
    const timestampDocRef = doc(this.firestore, `Timestamp/${_timestampDocPath}`)
    return docData(timestampDocRef) as Observable<TimestampList>
    //return this.firestoreModul.collection<TimestampList>('Timestamp').doc(_timestampString).valueChanges();
  }

  subscribeToAllUser(){
    const userCollection = collection(this.firestore, 'User');
    return collectionSnapshots(userCollection)
    .pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.data() as User;
          const uid = a.id;
          return { uid, ...data };
        })
      )
    );
  }




  async getSpecificTimestampData(_specifigTimestampPath: string): Promise<TimestampList> {
    const specificTimestampRef = doc(this.firestore, `Timestamp/${_specifigTimestampPath}`);
    const timestampData = (await getDoc(specificTimestampRef)).data() as TimestampList;
    let t_timestampList: TimestampList = this.setEmptyTimestampList(new Date(), 0);
    //const t_doc = await this.firestoreModul.collection<TimestampList>('Timestamp').doc(_specifigTimestampPath).ref.get();

    if (!timestampData) {
      return t_timestampList;
    }

    return timestampData;
  }




  async setOvertime(_overtime: number, _timestampDocPath: string) {
    const timestampDocRef = doc(this.firestore, `Timestamp/${_timestampDocPath}`)
    try {
      updateDoc(timestampDocRef, { lastMonthOvertime: _overtime });
    } catch {
      this.alertMessageSevice.alertMessage('Überstunden','Konnte nicht angelegt werden.');
    }
  }




  async setAbsent(_timestampDocPath: string, _$absent: Absentstamp[], _userPersonalNumber: number) {
    const timestampDocRef = doc(this.firestore, `Timestamp/${_timestampDocPath}`)
    const timestampData = await getDoc(timestampDocRef);
    //const t_timestamp = await this.firestoreModul.collection('Timestamp').doc(_timestampDocPath).ref.get();

    if (!timestampData.exists) {
      const t_newTimestamp = {
        loginTime: [],
        logoutTime: [],
        month: _$absent[0].month,
        personalNumber: _userPersonalNumber,
        sickAndHoliday: _$absent,
        year: _$absent[0].year,
      }
      setDoc(timestampDocRef, t_newTimestamp);
      return;
    }

    if (timestampData.get('sickAndHoliday')) {
      updateDoc(timestampDocRef, {sickAndHoliday: _$absent});
    }

    const t_absentstampList: Absentstamp[] = timestampData.get('sickAndHoliday');
    _$absent.forEach((firstElement) => {
      let isDontHave = true;
      for (let i = 0; t_absentstampList.length > i; ++i) {
        if (
          firstElement.day === t_absentstampList[i].day &&
          firstElement.month === t_absentstampList[i].month &&
          firstElement.year === t_absentstampList[i].year)
        {
          isDontHave = false;
          break;
        }
      }
      if (isDontHave) {
        t_absentstampList.push(firstElement);
      }
    });
    t_absentstampList.sort((a, b) => a.day - b.day);
    updateDoc(timestampDocRef, {sickAndHoliday: t_absentstampList,});
  }




  async deleteAbsent(_timestampDocPath: string, _$absent: Absentstamp[], _userPersonalNumber: number) {
    const timestampDocRef = doc(this.firestore, `Timestamp/${_timestampDocPath}`)
    const timestampData = await getDoc(timestampDocRef);
    
    if(!timestampData.exists){return;}

    const t_absentstampList: Absentstamp[] = await timestampData.get('sickAndHoliday');
    for (const t_currentDay of _$absent) {
      for (const t_absentDay of t_absentstampList) {
        if (
          t_currentDay.day === t_absentDay.day &&
          t_currentDay.month === t_absentDay.month &&
          t_currentDay.year === t_absentDay.year
        ) {
          t_absentstampList.splice(t_absentstampList.indexOf(t_absentDay), 1);
        }
      }
    }
    updateDoc(timestampDocRef, { sickAndHoliday: t_absentstampList });
  }




  async addLoginTime() {
    if (this.appUser.isOnline) {
      return;
    }

    const t_currentTime = new Date();
    const t_timestampDocPath = this.createTimestampDocPath(t_currentTime);
    const t_timestampDocRef = doc(this.firestore, `Timestamp/${t_timestampDocPath}`);
    const t_timestamp: TimestampList = (await getDoc(t_timestampDocRef)).data() as TimestampList;

  
    if (!this.appUser.hasAccessForTime) {
      if (t_currentTime.getHours() < 7) {
        t_currentTime.setHours(7);
        t_currentTime.setMinutes(0);
        this.alertMessageSevice.alertMessage('Einstempeln', 'Die Zeit wurde auf 7 Uhr gestellt.');
      }
    }

    const T_CURRENTTIMESTAMP = this.setCurrentTime(`${t_currentTime.getHours()}:${(t_currentTime.getMinutes()<10?`0${t_currentTime.getMinutes()}`:t_currentTime.getMinutes())}`,t_currentTime);
    t_timestamp.loginTime.push(T_CURRENTTIMESTAMP);

    const t_currentUserDocRef = doc(this.firestore, `User/${this.auth.currentUser.uid}`);

    updateDoc(t_currentUserDocRef, {
      isOnline: true,
      lastCheckIn: `${T_CURRENTTIMESTAMP.roundTime} (${t_currentTime.getDate()}.${t_currentTime.getMonth() + 1}.${t_currentTime.getFullYear()})`,
    });
    updateDoc(t_timestampDocRef, { loginTime: t_timestamp.loginTime });
  }




  async addLogoutTime() {
    if (!this.appUser.isOnline) {
      return;
    }

    const t_currentTime = new Date();
    const t_timestampDocPath = this.createTimestampDocPath(t_currentTime);
    const t_timestampDocRef = doc(this.firestore, `Timestamp/${t_timestampDocPath}`);
    const t_timestamp: TimestampList = (await getDoc(t_timestampDocRef)).data() as TimestampList;

    if (
      (t_currentTime.getHours() > 18 ||
        (t_currentTime.getHours() === 18 && t_currentTime.getMinutes() >= 1)) &&
      !this.appUser.hasAccessForTime
    ) {
      t_currentTime.setHours(18);
      t_currentTime.setMinutes(0);
      this.alertMessageSevice.alertMessage('Zu spät ausgeloggt','Ihre Zeit wurde auf 18:00 Uhr gestellt..');
    }

    const T_CURRENTTIMESTAMP = this.setCurrentTime(`${t_currentTime.getHours()}:${(t_currentTime.getMinutes()<10?`0${t_currentTime.getMinutes()}`:t_currentTime.getMinutes())}`, t_currentTime);
    T_CURRENTTIMESTAMP.time = t_currentTime.toString();
    t_timestamp.logoutTime.push(T_CURRENTTIMESTAMP);

    const t_currentUserDocRef = doc(this.firestore, `User/${this.auth.currentUser.uid}`);
    updateDoc(t_currentUserDocRef, {
      isOnline: false,
      lastCheckOut: `${T_CURRENTTIMESTAMP.roundTime} (${t_currentTime.getDate()}.${t_currentTime.getMonth() + 1}.${t_currentTime.getFullYear()})`,
    });
    updateDoc(t_timestampDocRef, { logoutTime: t_timestamp.logoutTime });
  }



  addEmail(emailMap: Map<string, string[]>, t_month: number, t_year: number){
    for (let [supervisor, employes] of emailMap) {
      let supervisorEmail: string = `${supervisor.split(' ')[0]}.${supervisor.split(' ')[1]}@wieprecht.services`;
      let employesString: string = '';
      const emailCollectionRef = collection(this.firestore, 'mail');

      employes.forEach(worker => {
        employesString = `${employesString}${worker}`
      })

      let emailDoc = {
        to: [supervisorEmail],
        message: {
          subject: 'Deine Mitarbeiterzeiten',
          text: '',
          html: `<h1>Deine Mitarbeiterzeiten:</h1><h2>${t_month}.${t_year}</h2><p>${employesString}</p>`
        }
      }


      addDoc(emailCollectionRef, emailDoc);
    }
  }



  //-------------------- Normal Function --------------------//
  async logout(): Promise<void> {
    this.auth.signOut();
  }




  async resetPassword(email: string): Promise<void> {
    sendPasswordResetEmail(this.auth, email);
  }




  async loginWithEmail(_data): Promise<boolean> {
    const T_USER = await signInWithEmailAndPassword(this.auth, _data.email, _data.password);
    if (T_USER.user.uid) {
      return true;
    } else {
      return false;
    }
  }




  async createCredentielForUser(_email: string, _passowrd: string){
    const newUserCredential = await createUserWithEmailAndPassword(this.auth, _email, _passowrd);
    return newUserCredential;
  }




  async createUserDoc(_user: User, _uid: string){
    const t_userDocRef = doc(this.firestore, `User/${_uid}`);
    setDoc(t_userDocRef, _user);
  }




  async updateUserDoc(_user){
    const t_userDocRef = doc(this.firestore, `User/${_user.uid}`);
    updateDoc(t_userDocRef, _user);
  }




  async updateTimestampList(_timestampDocPath: string, _timestamp: any){
    const t_timestampDocRef = doc(this.firestore, `Timestamp/${_timestampDocPath}`);
    updateDoc(t_timestampDocRef, _timestamp);
  }





  async checkPersonalNumber(_personalNumber): Promise<User | false> {
    const t_userCollectionRef = collection(this.firestore, 'User');
    const q = query(t_userCollectionRef, where('personalNumber', '==', _personalNumber), limit(2));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      this.alertMessageSevice.alertMessage('Personnalnummer', 'Personalnummer nicht gefunden');
      return false;
    }
    if(querySnapshot.size > 1){
    this.alertMessageSevice.alertMessage('Personnalnummer', 'Was? Es exisitieren 2 Personen mit dieser Personalnummer?');
    }
    
    let t_searchUser: User = null;
    querySnapshot.docs.map(user => {
      t_searchUser = user.data() as User;
    });
    return t_searchUser;
  }




  async downloadAllTimestampThisMonth(specificMonth: number,specificYear: number) {
    const t_timestampCollectionRef = collection(this.firestore, 'Timestamp');
    const timestampQuery = query(t_timestampCollectionRef, where('month', '==', specificMonth), where('year', '==', specificYear));
    const timestampSnapshot = await getDocs(timestampQuery);

    if (timestampSnapshot.empty) {
      this.alertMessageSevice.alertMessage('Timestamps', 'Konnte nicht gefunden werden!');
      return null;
    }

    const allTimestamps: TimestampList[] = [];
    timestampSnapshot.docs.map(timestampList => {
      allTimestamps.push(timestampList.data() as TimestampList);
    });
    return allTimestamps;
  }




  private async checkTimestampsFromDataBase(_user: User) {
    const t_currentTime = new Date();
    const timestampDocPath = this.createTimestampDocPath(t_currentTime);

    const timestampDocRef = doc(this.firestore, `Timestamp/${timestampDocPath}`);
    const timestampDoc = await getDoc(timestampDocRef);

    if(timestampDoc.exists()){
      this.currentTimestampList = timestampDoc.data() as TimestampList;
    }else{
      this.currentTimestampList = this.setEmptyTimestampList(t_currentTime, this.appUser.personalNumber);
      setDoc(timestampDocRef, this.currentTimestampList);
    }
  }




  private setCurrentTime(_calculatedRoundTime: string, _currentTime: Date) {
    return {
      roundTime: _calculatedRoundTime,
      time: _currentTime.toString(),
      day: _currentTime.getDate(),
      mark: 'S',
      month: _currentTime.getMonth() + 1,
      year: _currentTime.getFullYear(),
    };
  }




  private setEmptyTimestampList(_currentTime: Date, _pNumber: number): TimestampList {
    return {
      personalNumber: _pNumber,
      month: _currentTime.getMonth() + 1,
      year: _currentTime.getFullYear(),
      loginTime: [],
      logoutTime: [],
      sickAndHoliday: [],
      lastMonthOvertime: null,
    };
  }




  private createTimestampDocPath(_currentTime: Date): string {
    return `${_currentTime.getMonth() + 1}-${_currentTime.getFullYear()}_${
      this.appUser.personalNumber
    }`;
  }




  private authStatusListener(_oneTimeSwitch: boolean) {
    onAuthStateChanged(this.auth, async (credential) => {
      if (credential) {
        this.isAuthenticated.next(true);
        this.appUserSubscriper = this.subscribeUserWithUid(credential.uid).subscribe( async (user) => {
            if (!user) {return;}
            if(user !== this.appUser){

            }
            this.appUser = user;
            this.appUserObservable.next(user);
            if (_oneTimeSwitch) {
              _oneTimeSwitch = false;
              await this.checkTimestampsFromDataBase(user);
              if (this.currentTimestampList) {
                this.timestampObservable.next(this.currentTimestampList);
              }
            }
          }
        );
      } else {
        if(this.appUserSubscriper !== null){
          this.appUserSubscriper.unsubscribe();
        }
        this.isAuthenticated.next(false);
        this.appUser = null;
        this.currentTimestampList = null;
        this.timestampObservable.next(this.currentTimestampList);
        this.appUserObservable.next(this.appUser);
      }
    });
  }
}
