import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseService } from '../../_services/firebase.service';
import { AlertMessageService } from '../../_services/alert-message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: false,
})
export class LoginPage implements OnInit {
  credentials: FormGroup;
  containerVisibility: boolean = true;
  canResetPassword: boolean = false;


  constructor(
    private fbModul: FormBuilder,
    private authService: FirebaseService,
    private routerModul: Router,
    private alertMessageService: AlertMessageService
    ) {}

  ngOnInit() {
    this.credentials = this.fbModul.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  checkValidEmail(_email: string | number){
    const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if(typeof(_email) === 'number' || !emailPattern.test(_email)){
      this.canResetPassword = false;
    }
    this.canResetPassword = true;
  }

  async resetPassword(_email: string | number){
    if(typeof(_email) === 'number'){
      return
    }
    await this.authService.resetPassword(_email);
  }

  async login() {
    let t_res: any | null = null;
    try{
      t_res = await this.authService.loginWithEmail(this.credentials.value);
    }catch{
      this.alertMessageService.alertMessage('Login', 'Passwort oder Email sind falsch');
    }

    if(t_res){
      this.routerModul.navigateByUrl('/tab-manager', {replaceUrl: true});
    }
  }

  get email(){
    return this.credentials.get('email');
  }

  get password(){
    return this.credentials.get('password');
  }
}
